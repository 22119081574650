import "./styles.css";

import CtaWppOther from "../../components/CTAWppOther";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <div className="content">
            <h1 className="title">
              Loucuras de <span>amor</span>
            </h1>

            <p className="text">
              Homenagens com <b>carro de som</b>, para quem você ama!
            </p>

            <CtaWppOther />
          </div>
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title">
            DÊ A QUEM VOCÊ AMA, UM PRESENTE PARA GUARDAR NO CORAÇÃO!
          </h2>

          <p className="text">
            Homenageie quem você ama ou admira, com uma linda mensagem, com
            carro de som ou por telefone!{" "}
          </p>

          <CtaWppOther />
        </article>
      </section>
    </header>
  );
};

export default Header;
