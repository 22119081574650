import "./styles.css";

import CtaWppOther from "../../components/CTAWppOther";
import CtaWpp from "../../components/CTAWpp";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import bullet from "../../assets/bullet.png";
import about2 from "../../assets/about2.png";
import about3_1 from "../../assets/about3_1.png";
import about3_2 from "../../assets/about3_2.png";
import about3_3 from "../../assets/about3_3.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <h2 className="title">nossos serviços</h2>

          <p className="text">
            As homenagens são ideais para aniversários, celebrar amizades,
            demonstração amor e carinho ou oque mais você imaginar!
          </p>

          <div className="gallery">
            <div className="item">
              <figure className="top">
                <img src={about1_1} alt="Homenagens com carro de som" />
              </figure>

              <div className="bottom">
                <h2 className="item_title">Homenagens com carro de som</h2>

                <p className="text">
                  Preparamos uma mensagem linda e exclusiva para o seu
                  homenageado(a) e vamos até ele(a) com nosso carro de som para
                  realizar uma homenagem mágica.
                </p>

                <CtaWppOther />
              </div>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about1_2} alt="telemensagem por telefone" />
              </figure>

              <div className="bottom">
                <h2 className="item_title">telemensagem por telefone</h2>

                <p className="text">
                  Preparamos uma mensagem linda e exclusiva para o seu
                  homenageado(a) e realizamos a homenagem por telefone.
                </p>

                <CtaWppOther />
              </div>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about1_3} alt="festa na caixa" />
              </figure>

              <div className="bottom">
                <h2 className="item_title">festa na caixa</h2>

                <p className="text">
                  Oferecemos também a festa na caixa, onde entregamos uma caixa
                  decorada e recheada com um kit festa.
                  <span>*Verificar os itens inclusos, na contratação.</span>
                </p>

                <CtaWppOther />
              </div>
            </div>
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <div className="left">
            <div className="top">
              <h2 className="title">Pacote especial</h2>
              <h3 className="title2">Pacote bronze</h3>
              <figure className="mobile">
                <img
                  src={about2}
                  alt="Fotos de pessoas que receberam o Carro da Telemensagem"
                />
              </figure>
            </div>

            <ul>
              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Chegamos com o carro enfeitado com balões;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>1 mensagem na hora;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>2 músicas à sua escolha;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>
                  Fazemos a entrega de 1 brinde (coracão de pelúcia ou champagne
                  ou caixa de bombom);
                </p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Queima de fogos(vulcão colorido);</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Sessão de discurso dos convidados e do homenageado.</p>
              </li>
            </ul>

            <p className="text">
              <span>Presentes opcionais: </span>
              Cesta de café e chocolate, buquê de rosas, Bolos, Chuva de
              pétalas.
            </p>

            <CtaWppOther />
          </div>

          <figure className="right">
            <img
              src={about2}
              alt="Fotos de pessoas que receberam o Carro da Telemensagem"
            />
          </figure>
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <h2 className="title">nosso diferencial</h2>

          <p className="text">
            Nossa missão é encantar e emocionar a quem você ama ou admira, com
            mensagens lindas e bem elaboradas.
          </p>

          <div className="gallery">
            <figure>
              <img src={about3_1} alt="LOCUÇÃO DE EXCELÊNCIA" />
            </figure>

            <figure>
              <img src={about3_2} alt="AMOR E EMOÇÃO " />
            </figure>

            <figure>
              <img src={about3_3} alt="ATENDIMENTO DE QUALIDADE" />
            </figure>
          </div>

          <h3 className="title">CONTRATE AGORA MESMO PELO WHATSAPP: </h3>

          <CtaWpp />
        </div>
      </article>
    </section>
  );
};

export default About;
